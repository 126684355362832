import { Image } from 'components/Image'
import { useEffect, useState, useContext, useMemo } from 'react'
import { debounce as _debounce } from 'lodash-es'
import useMobileNavState from 'hooks/useMobileNavState'
import useIsSsr from 'hooks/useIsSsr'
import { useViewportSmallerThan } from 'utils/viewport'
import { BREAKPOINTS } from 'constants/viewport'
import { Box, Button, Typography, AchieveTheme } from '@achieve/ascend'
import Container from '@mui/material/Container'
import { AchieveLink } from 'components/AchieveLink/AchieveLink'
import { SignInLink } from 'components/SignInLink'
import Navigation from 'components/Navigation'
import HeaderMobileActions from './HeaderMobileActions'
import HeaderCta from './HeaderCta'
import styles from './Header.module.scss'
import dynamic from 'next/dynamic'
import { handleTrackAndReactEvent } from 'utils/analytics'
import useLayoutMenuCondensed from 'hooks/useLayoutMenuCondensed'
import { LayoutContext } from 'providers/LayoutProvider'
import { X as CloseIcon, Plus } from 'react-feather'
import { useTriggerEvents } from 'hooks/useTriggerEvents'
import { PhoneDataContext } from 'providers/PhoneDataProvider'
import { MediaImageStandard } from 'components/Contentful'
import Head from 'next/head'
import MenuIcon from 'components/Svgs/Menu.js'
import Close from 'components/Svgs/Close.js'
import { mainProductConfig } from 'services/contentful/helpers'
import { tenant2, WfsPage, FdrPage, FfnPage, AchievePage } from 'utils/environmentConfig'
import classNames from 'classnames'
import { useRouter } from 'next/router'
import { useFeatureFlag } from 'hooks/useFeatureFlag'
import { checkIfExperimentIsOn } from 'utils/experiments'
import { FEATURE_TOGGLES } from 'constants/featureToggles'
import { get as _get } from 'lodash-es'
import { Typography as OwnerTypography } from 'components/Contentful'
const AchieveLogoSVG = dynamic(() => import('components/Svgs/AchieveLogoSVG.js'))
// TODO: Replace with SVG
// const Website1LogoSVG = dynamic(() => import('components/Svgs/Website1LogoSVG.js'))
const Website2LogoSVG = dynamic(() => import('components/Svgs/Website2LogoSVG.js'))
const Website3LogoSVG = dynamic(() => import('components/Svgs/Website3LogoSVG.js'))
const CurrentSvg = ({ siteEnv, ...props }) => {
  switch (siteEnv) {
    case WfsPage:
      return (
        <Image
          src="/wfs-logo-dark.png"
          title="WFS Logo"
          alt="WFS Logo"
          width={250}
          height={60}
          {...props}
        />
      )
    case tenant2:
      return <Website2LogoSVG title="website-tenant-2" alt="website-tenant-2" {...props} />
    case FdrPage:
      return <Website2LogoSVG title="FDR Logo" alt="FDR Logo" {...props} />
    case FfnPage:
      return <Website3LogoSVG title="FFN Logo" alt="FFN Logo" {...props} />
    default:
      return <AchieveLogoSVG title="Achieve Logo" alt="Achieve Logo" {...props} />
  }
}
// Percentage of the window that needs to scroll for the mobile header to condense
const MOBILE_CONDENSED_THRESHOLD = 0.3

/**
 * Main site Header component
 * @param {{
 *  mainNavigation: object
 *  disabledRoutes: []
 * }} props
 */
export default function Header({
  disabledElements,
  disabledRoutes,
  mainNavigation,
  logoEvent,
  signInLinkUrl,
  signInLinkText,
  signInEvent,
  linkUrl,
  linkText,
  headerCta = true,
  linkEvent,
  showNav = true,
  showSignIn = true,
  customPhoneData = null,
  partnerLogo,
  logoLink = true,
  showProgressPage = false,
  siteEnv,
  footerSticky,
  showPhoneNumber = false,
}) {
  const [, setLayoutMenuCondensed] = useLayoutMenuCondensed()
  const { fireAndForget } = useTriggerEvents()
  const [mobileNavOpen, setMobileNavOpen] = useMobileNavState()
  const [condensed, setCondensed] = useState(false)
  const [mobileCondensed, setMobileCondensed] = useState(false)
  const isSsr = useIsSsr()
  const mqHookResults = useViewportSmallerThan(BREAKPOINTS.lg)
  const [buttonCall, setButtonCall] = useState(false)
  let phoneData = useContext(PhoneDataContext)

  const { state: { isMobileUA } = {} } = useContext(LayoutContext)
  const phoneIconUrl = '/multi-site-assets/icon-phone.svg'
  const phoneIconBlueUrl = '/multi-site-assets/phone-blue.svg'

  const [progressPage, setProgressPage] = useState(0)
  const usesStickyFooter = useFeatureFlag(FEATURE_TOGGLES.ACX_STICKY_FOOTER_HEADER)
  const experimentOn = checkIfExperimentIsOn(usesStickyFooter)
  const router = useRouter()
  const pathsNotToShowStickyFooter = ['/', '/l/[slug]', '/a/[slug]']
  const tp_text = _get(footerSticky, 'card.subtitle')
  const tp_image = _get(footerSticky, 'card.image')

  const showStickyFooter = experimentOn && !pathsNotToShowStickyFooter.includes(router.pathname)

  /**
   * The below logic is used specifically for home-equity-loans-mp. AHL Direct Mail campaign
   * requires phone number to be based on utm_source and utm_content. Phone number data is
   * fetched from pageConfig object in Home Equity Loan MP contentful page entry.
   * Implemented by PXP team.
   * Contact: Shreyas Dorle, Brandon Chapman
   */
  if (customPhoneData) {
    const { utm_source, utm_content, ahl_dm_creatives } = customPhoneData ?? {}
    const {
      informed_delivery,
      digital,
      default_values,
      utm_source: ahl_dm_utm_source,
    } = ahl_dm_creatives ?? {}
    if (utm_source === ahl_dm_utm_source) {
      if (utm_content?.toUpperCase() === informed_delivery?.utm_content) {
        phoneData.phoneNumber = informed_delivery?.phone
      } else if (utm_content?.toUpperCase() === digital?.utm_content) {
        phoneData.phoneNumber = digital?.phone
      } else {
        phoneData.phoneNumber = default_values?.phone
      }
    }
  }

  // Default to mobile first before initial render if the user agent matches a mobile device
  const isMobile = isSsr ? isMobileUA : mqHookResults

  const debounceOptions = {
    leading: true,
    trailing: false,
  }

  const setMobileCondensedTrue = _debounce(() => setMobileCondensed(true), 100, debounceOptions)
  const setMobileCondensedFalse = _debounce(() => setMobileCondensed(false), 100, debounceOptions)
  const setCondensedTrue = _debounce(() => setCondensed(true), 100, debounceOptions)
  const setCondensedFalse = _debounce(() => setCondensed(false), 100, debounceOptions)

  const isDebtConsolidation = router?.query?.slug === 'debt-consolidation'

  /**
   * Set the initial condensed state based on window scroll position
   */
  useEffect(() => {
    if (isSsr) {
      // no window to measure during SSR
      return
    }

    onScroll()
    // This effect is only intended to run on the first browser (not SSR) render
  }, [isSsr]) /* eslint-disable-line react-hooks/exhaustive-deps */

  useEffect(() => {
    const handleScroll = _debounce(() => {
      onScroll()
    }, 30)
    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  })

  function onScroll() {
    const currentScroll = window.scrollY
    const scrollHeight = document.documentElement.scrollHeight - window.innerHeight
    const scrollPercentage = (currentScroll / scrollHeight) * 100
    setProgressPage(scrollPercentage)

    if (isMobile) {
      return handleMobileScroll()
    }
    return handleDesktopScroll()
  }

  /**
   * Enable the condensed mobile header when the page is scrolled passed the threshold
   */
  function handleMobileScroll() {
    const currentScroll = window.scrollY
    const nextMobileCondensed = currentScroll > window.innerHeight * MOBILE_CONDENSED_THRESHOLD

    if (nextMobileCondensed === mobileCondensed) {
      return
    }

    if (nextMobileCondensed) {
      return setMobileCondensedTrue()
    }

    return setMobileCondensedFalse()
  }

  /**
   * Enable the condensed desktop header when the page is scrolled any distance from the top
   */
  function handleDesktopScroll() {
    const currentScroll = window.scrollY
    const nextCondensed = Boolean(currentScroll)

    if (nextCondensed === condensed) {
      return
    }

    if (nextCondensed) {
      return setCondensedTrue()
    }

    return setCondensedFalse()
  }

  /*
    Prevent body scroll when the mobile navigation menu is open
  */
  useEffect(() => {
    const body = document.querySelector('body')

    if (!body) {
      return
    }

    if (isMobile && mobileNavOpen) {
      // Prevent the body scrolling under the open mobile nav menu by fixing the body element,
      // setting the height to the viewport height and setting the overflow to hidden
      body.style.position = 'fixed'
      body.style.height = '100vh'
      body.style.width = '100vw'
      body.style.overflow = 'hidden'
      return
    }

    // Reset the body css to scroll and overflow normally.
    body.style.position = 'initial'
    body.style.height = 'initial'
    body.style.width = 'initial'
    body.style.overflow = 'initial'
  }, [isMobile, mobileNavOpen])

  const handleCloseClick = (event, track) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track),
    })
    !isSsr && window.scrollTo({ top: 0, behavior: 'instant' })
    setMobileNavOpen(false)
  }

  const handleOpenClick = (event, track) => {
    fireAndForget({
      event_type: track?.event_type,
      ...handleTrackAndReactEvent(event, track),
    })
    setMobileNavOpen(true)
  }

  useEffect(() => {
    setLayoutMenuCondensed(isMobile ? mobileCondensed : condensed)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isMobile, condensed, mobileCondensed])

  const callToAssociatedNumber = () => {
    if (phoneData?.phoneNumber) window.open(`tel:${phoneData.phoneNumber}`, '_self')
  }

  const handleCall = () => {
    if (isMobile) callToAssociatedNumber()
    else setButtonCall(!buttonCall)
  }

  const getSubNav = () => {
    if (!isMobile || !phoneData?.phoneNumber) return ''
    return (
      <Box className={styles['header-subnav']}>
        <Typography variant="bodyLg" fontWeight="regular" data-testid="header-subnav-phone-copy">
          {phoneData?.phoneCopy}
        </Typography>
        <AchieveLink
          className={styles['title-link']}
          noLink
          track={{
            list_name: 'PHONE CALL CLICK HEADER',
            click_id: phoneData?.phoneNumber,
            click_text: `${mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']} | ${
              phoneData?.phoneNumber
            }`,
            click_url: phoneData?.phoneNumber,
            click_type: 'Phone Click',
            event_action: 'button_click',
            nav_link_section: 'Header',
            track_event: 'global_header',
          }}
        >
          <Button
            variant="outlined"
            size="small"
            startIcon={getMobileIcon()}
            onClick={callToAssociatedNumber}
            data-testid="header-subnav-phone-button"
          >
            {phoneData?.phoneNumber}
          </Button>
        </AchieveLink>
      </Box>
    )
  }

  const getMobileIcon = () => {
    if (siteEnv === FfnPage || (siteEnv === AchievePage && isDebtConsolidation)) {
      return (
        <MediaImageStandard
          className={styles['phone-icon']}
          alt={'phone icon'}
          height={20}
          content={{ url: phoneIconBlueUrl }}
          width={20}
          layout="fill"
          objectFit="contain"
          onClick={handleCall}
        />
      )
    }

    return (
      <Box position={'relative'}>
        <MediaImageStandard
          className={styles['phone-icon']}
          alt={'phone icon'}
          height={condensed ? 20 : 24}
          content={{ url: phoneIconUrl }}
          width={condensed ? 20 : 24}
          layout="fill"
          objectFit="contain"
          onClick={handleCall}
        />
      </Box>
    )
  }

  const getCallDesktop = () => {
    return (
      <Box className="flex flex-row gap-2 cursor-pointer">
        <MediaImageStandard
          classNameContainer="relative"
          alt={'phone icon'}
          height={24}
          content={{ url: phoneIconBlueUrl }}
          width={24}
          layout="fill"
          onClick={handleCall}
        />
        <Typography variant="bodyLg" fontWeight="regular" component="p">
          {phoneData?.phoneNumber}
        </Typography>
      </Box>
    )
  }

  const AchieveLogo = ({ mobile, logoLink }) => {
    /*Combination as follows:
      Mobile with Link
      Mobile without Link
      Desktop with Link
      Desktop without Link
    */
    return mobile ? (
      logoLink ? (
        <AchieveLink
          track={{
            list_name: 'Header Logo',
            click_id: `2022_${mainProductConfig[siteEnv || 'achieve']?.['MainName']?.replace(
              / /g,
              '_'
            )}_Logo_RGB`,
            click_text: `${
              mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
            } | Header logo`,
            nav_link_section: 'Header logo',
            track_event: 'global_header',
            event_action: 'link_click',
            event_type: 'link_click',
          }}
          href="/"
          withNextLink
          className={styles['mobile-logo']}
          data-testid={`${String(
            mainProductConfig[siteEnv || 'achieve']?.['MainName']
          )?.toLowerCase()}-header-mobile-logo`}
          onClick={() => setMobileNavOpen(false)}
        >
          <CurrentSvg siteEnv={siteEnv} className={styles['logo-svg']} />
        </AchieveLink>
      ) : (
        <div className={styles['mobile-logo']}>
          <CurrentSvg siteEnv={siteEnv} />
        </div>
      )
    ) : logoLink ? (
      <AchieveLink
        track={{
          ...{
            list_name: 'Header Logo',
            click_id: `${mainProductConfig[siteEnv || 'achieve']?.['HeaderLogo']}`,
            click_text: `${
              mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
            } | Header logo`,
            nav_link_section: 'Header logo',
            track_event: 'global_header',
            event_action: 'link_click',
            event_type: 'link_click',
          },
          ...logoEvent,
        }}
        href="/"
        withNextLink
        className={styles['logo']}
        data-testid={`${String(
          mainProductConfig[siteEnv || 'achieve']?.['MainName']
        )?.toLowerCase()}-header-logo`}
      >
        <CurrentSvg siteEnv={siteEnv} />
      </AchieveLink>
    ) : (
      <div className={styles['logo']}>
        <CurrentSvg siteEnv={siteEnv} />
      </div>
    )
  }

  const PartnerLogo = ({ src }) => {
    return (
      <div className={styles['partner-logo']}>
        <Image
          src={src}
          alt={`${mainProductConfig[siteEnv || 'achieve']?.['MainName']} partner logo`}
          className={styles['logo-image']}
          height="60"
          width="180"
          priority={true}
        />
      </div>
    )
  }

  const AchieveLogoMobile = useMemo(
    () => <AchieveLogo mobile={true} logoLink={logoLink} />,
    [logoLink]
  )

  const AchieveLogoDesktop = useMemo(
    () => <AchieveLogo mobile={false} logoLink={logoLink} />,
    [logoLink]
  )

  return (
    <>
      <header
        className={styles.header}
        data-condensed={condensed}
        data-mobile-condensed={mobileCondensed}
        data-mobile-nav-open={mobileNavOpen}
        data-narrow={true}
        data-testid="acx-website-header"
        data-product={siteEnv}
        data-slug={router?.query?.slug}
      >
        {showPhoneNumber && (
          <Head>
            <link rel="preload" href={phoneIconUrl} as="image" type="image/svg+xml" />
          </Head>
        )}
        <Container
          maxWidth={false}
          disableGutters
          className={styles['header-content-container']}
          data-narrow={true}
        >
          <div className={styles['header-content']}>
            {/* Container for content shown in the mobile header bar */}
            <div className={styles['mobile-header']}>
              {partnerLogo ? (
                <div className={styles['logo-flex']}>
                  {AchieveLogoDesktop}
                  <div className={styles['logo-container']}>
                    <Plus height={30} width={30} />
                  </div>
                  <PartnerLogo src={partnerLogo?.file?.url} />
                </div>
              ) : (
                AchieveLogoDesktop
              )}
              {AchieveLogoMobile}
              {/* Mobile nav menu open and close buttons */}
              <div className={styles['mobile-menu-controls']}>
                {!mobileCondensed && showStickyFooter && (
                  <HeaderCta
                    linkUrl={linkUrl}
                    linkText={linkText}
                    size="small"
                    className={classNames('mr-10', {
                      'mr-[90px]': isMobile && phoneData?.phoneNumber,
                    })}
                    data-testid="header-get-started-button"
                  />
                )}
                {isMobile && phoneData?.phoneNumber && showPhoneNumber && (
                  <Box
                    className={styles['call-cta']}
                    data-call-cta-mobile={true}
                    data-open-button-call={buttonCall}
                  >
                    <AchieveLink
                      className={styles['call-cta-link']}
                      noLink
                      track={{
                        list_name: 'PHONE CALL CLICK HEADER',
                        click_id: phoneData?.phoneNumber,
                        click_text: `${
                          mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
                        } | ${phoneData?.phoneNumber}`,
                        click_url: phoneData?.phoneNumber,
                        click_type: 'Phone Click',
                        event_action: 'button_click',
                        nav_link_section: 'Header',
                        track_event: 'global_header',
                      }}
                      data-testid="mobile-phone-number-link"
                    >
                      {getMobileIcon()}
                    </AchieveLink>
                  </Box>
                )}
                {showNav && (
                  <>
                    <button
                      onClick={(e) => {
                        handleOpenClick(e, {
                          nav_link_section: 'Toggle menu',
                          click_type: 'Button Click',
                          click_id: 'open button',
                        })
                      }}
                      className={styles['mobile-menu-button']}
                      data-testid="mobile-nav-open-button"
                      aria-label="Main navigation menu button"
                    >
                      <MenuIcon />
                    </button>
                    <button
                      onClick={(e) => {
                        handleCloseClick(e, {
                          nav_link_section: 'Toggle menu',
                          click_type: 'Button Click',
                          click_id: 'close button',
                        })
                      }}
                      role="button"
                      className={styles['mobile-close-button']}
                      data-testid="mobile-nav-close-button"
                      aria-label="Main navigation menu close button"
                    >
                      <Typography variant="bodyXs" fontWeight="medium" component="span">
                        CLOSE
                      </Typography>
                      <Close title="Close button" />
                    </button>
                  </>
                )}
              </div>
            </div>
            {/* Main nav and sub nav list components */}
            {((showNav && !showStickyFooter) || (showNav && showStickyFooter)) && (
              <Navigation
                event={linkEvent}
                condensed={condensed}
                mobileNavOpen={mobileNavOpen}
                items={mainNavigation}
                disabledRoutes={disabledRoutes}
                disabledElements={disabledElements}
                mobileSubnav={getSubNav()}
                className={{ block: true, 'lg:hidden': showStickyFooter && condensed }}
              />
            )}
            {/* Right Panel */}
            <div
              className={classNames(styles['header-right-section'], {
                'max-w-max': showStickyFooter,
              })}
              data-open-button-call={buttonCall}
              data-none-phone-number={phoneData?.phoneNumber ? false : true}
            >
              {!isMobile && phoneData?.phoneNumber && showPhoneNumber && isDebtConsolidation && (
                <Box className={styles['phone-icon-desktop']}>
                  <AchieveLink
                    noLink
                    track={{
                      list_name: 'PHONE CALL CLICK HEADER',
                      click_id: phoneData?.phoneNumber,
                      click_text: `${
                        mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
                      } | ${phoneData?.phoneNumber}`,
                      click_url: phoneData?.phoneNumber,
                      click_type: 'Phone Click',
                      event_action: 'button_click',
                      nav_link_section: 'Header',
                      track_event: 'global_header',
                    }}
                    data-testid="mobile-phone-number-link"
                  >
                    {getCallDesktop()}
                  </AchieveLink>
                </Box>
              )}
              {phoneData?.phoneNumber &&
                !isMobile &&
                showPhoneNumber &&
                !condensed &&
                showStickyFooter && (
                  <Box className={styles['call-cta']}>
                    <Box className={styles['call-cta-container']}>
                      {getMobileIcon()}
                      <Box className={styles['button-call-link']}>
                        <AchieveLink
                          noLink
                          track={{
                            list_name: 'PHONE CALL CLICK HEADER',
                            click_id: phoneData?.phoneNumber,
                            click_text: `${
                              mainProductConfig[siteEnv || 'achieve']?.['MainProductHyphen']
                            } | ${phoneData?.phoneNumber}`,
                            click_url: phoneData?.phoneNumber,
                            click_type: 'Phone Click',
                            event_action: 'button_click',
                            nav_link_section: 'Header',
                            track_event: 'global_header',
                          }}
                          data-testid="desktop-phone-number-link"
                        >
                          <Typography
                            variant="bodySm"
                            fontWeight="regular"
                            onClick={() => callToAssociatedNumber()}
                            data-testid="desktop-phone-number"
                          >
                            {phoneData?.phoneNumber}
                          </Typography>
                        </AchieveLink>
                      </Box>
                      <CloseIcon
                        className={styles['icon-close-link']}
                        width={18}
                        height={18}
                        color={AchieveTheme?.ascend?.colors?.neutral?.[150]}
                        onClick={handleCall}
                      />
                    </Box>
                  </Box>
                )}
              {condensed && showStickyFooter && phoneData?.phoneNumber && (
                <div className="flex flex-row gap-2 hidden lg:block">
                  <AchieveLink
                    noLink
                    track={{
                      list_name: 'CALL STICKY HEADER',
                      click_id: phoneData?.phoneNumber,
                      click_text: `Call us`,
                      click_url: phoneData?.phoneNumber,
                      click_type: 'Call us Click',
                      event_action: 'button_click',
                      nav_link_section: 'Sticky Header',
                      track_event: 'call-sticky- hidden lg:block',
                    }}
                    data-testid="call-sticky- hidden lg:block"
                  >
                    <Button
                      color="secondary"
                      variant="contained"
                      className="w-full flex flex-row gap-2"
                      size="small"
                      onClick={() => callToAssociatedNumber()}
                    >
                      <MediaImageStandard
                        className="object-contain relative"
                        alt={'phone icon'}
                        height={20}
                        content={{ url: phoneIconUrl }}
                        width={20}
                        layout="fill"
                      />
                      <OwnerTypography
                        variant="displayXs"
                        fontWeight="bold"
                        content={phoneData?.phoneNumber}
                      />
                    </Button>
                  </AchieveLink>
                </div>
              )}
              {/* "Get Started" CTA button is always present in desktop and is hidden and shown on
                scroll in mobile. */}
              {headerCta && (
                <HeaderCta
                  linkUrl={linkUrl}
                  linkText={linkText}
                  size="small"
                  data-condensed={isMobile || condensed}
                  className={classNames(styles['header-cta'], {
                    'hidden lg:block': showStickyFooter,
                  })}
                  data-testid="header-get-started-button"
                />
              )}
              {((showSignIn && !showStickyFooter) ||
                (showSignIn && showStickyFooter && !condensed)) && (
                <SignInLink
                  signInLinkUrl={signInLinkUrl}
                  signInLinkText={signInLinkText}
                  event={signInEvent}
                  className={styles['sign-in-link']}
                  data-testid="header-sign-in-link"
                  disabledRoutes={disabledRoutes}
                  underline="hover"
                />
              )}
              {condensed && showStickyFooter && (
                <div className="flex flex-row gap-2 hidden lg:block">
                  <div className="flex flex-row gap-2 items-center justify-center">
                    <div className="w-[165px] h-[20px]">
                      <MediaImageStandard content={tp_image} />
                    </div>
                    <OwnerTypography variant="bodyXs" className="text-black" content={tp_text} />
                  </div>
                </div>
              )}
            </div>
            {/* Action bar that shows at the bottom of the mobile nav */}
            {isMobile && (
              <HeaderMobileActions disabledRoutes={disabledRoutes} headerCta={headerCta} />
            )}
          </div>
        </Container>
        {condensed && showProgressPage && (
          <div className={classNames(styles['progress-page'], '-z-10')}>
            <div className={styles['progress']} style={{ width: `${progressPage}%` }} />
          </div>
        )}
      </header>
    </>
  )
}
